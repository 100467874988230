
.activities-list li {
    list-style: none;
}

.activity-line {
    padding: .5rem;
    color: rgb(200, 200, 200);
    margin: 1rem;
    border-radius: .5rem;
    font-weight: 650;

}

.activity-line:hover {
    background-color: rgba(30,40,52);
}

.activity-line .link {
    color: rgb(200, 200, 200);
    
}

.activity-line .link:hover {
    cursor: pointer;
    color: gray;
}

.commented {
    color: rgb(33, 87, 255); /* Strong blue */
}
.liked {
    color: rgb(47, 204, 112); /* Bright green */
}

.submitted {
    color: rgb(204, 51, 255); /* Vivid purple */
}

.rated {
    color: rgb(65, 123, 255); /* Sky blue */
}

.replied {
    color: rgb(0, 174, 255); /* Vivid cyan */
}

.posted {
    color: rgb(148, 103, 255); /* Soft lavender */
}

.answered {
    color: rgb(63, 255, 184); /* Fresh mint green */
}

.followed {
    color: rgb(145, 50, 255); /* Deep purple */
}

.favorited {
    color: rgb(0, 162, 255); /* Bright azure blue */
}

.thought {
    color: rgb(255, 71, 168); /* Pinkish-purple */
}




.activity-page {
    text-align: left;
}


.filter-dropdown-container {
    position: relative;
    display: inline-block;
    margin-left: 80%;
}

.filter-dropdown-btn {
    background-color: #007BFF;
    color: rgb(200, 200, 200);
    padding: 5px;
    font-size: 12px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
}

.filter-dropdown {
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: rgb(200, 200, 200);
    color: #007BFF;
    min-width: 160px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 4px;
    padding: 10px;
}

.filter-dropdown label {
    margin: 8px 0;
    cursor: pointer;
}

.activity-prompt {
    background-color: rgb(200, 200, 200);
    color:rgba(25,28,36);
    border-radius: 10px;
    font-weight: 600;
}

.activity-date {
    font-size: .75rem;
}

.activity-text {
    margin-left: 2rem;
}

.activity-delete {
    color: #222;
}

.activity-delete:hover {
    color: #333;
    cursor: pointer;
}