.potds {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Add spacing between prompts */
    justify-content: center;
    align-self: center;
}

.potd {
    background-color: rgb(8, 8, 8);
    padding: .6rem;
    color: rgb(200, 200, 200);
    font-size:  1.1rem;
    border-radius: 1em;
    box-shadow: 0 2px 4px rgba(234, 228, 228, 0.3); /* Subtle shadow for better separation */
    width: 16rem;
    height: 20rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


.potd h5{
    font-weight: 600;
}

.potd h5:hover {
    cursor: pointer;
    color:#007bff;
}

.potd-prompt:hover {
    background-color:#007bff;
    color: rgb(200, 200, 200);
}

.potd-prompt{
    padding: 1rem;
    width: 16rem;
    border-radius: 10px;
}
.potd-bottom {
    align-self: flex-end;
}

.potd-header:hover {
    cursor: pointer;
    color: rgb(141, 138, 138);
}

@media(max-width: 700px){
    .potd {
        width: 22rem;
        height: 22rem;
        font-size: 1.4rem;
        display: inline;
    }
    .potd-prompt{
        width: auto;
    }
}