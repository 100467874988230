/* General page styling */
.judging-page {
    background-color:rgba(2, 11, 29, 0.80);
    color: rgb(200, 200, 200);
    padding: 2rem;
    font-family: Arial, sans-serif;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-top: 1rem;
}

.judging-page header {
    text-align: center;
    margin-bottom: 2rem;
}

.judging-page header h1 {
    margin: 0;
    color: rgb(200, 200, 200);
}

/* Contest dropdown styling */
.contest-dropdown {
    margin-bottom: 2rem;
}

.contest-dropdown label {
    display: block;
    margin-bottom: 0.5rem;
    color: #ddd;
}

.contest-dropdown select {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #555;
    border-radius: 4px;
    background-color: #222;
    color: rgb(200, 200, 200);
}

/* Story selection and score input styling */
.story-fullscreen {
    background-color: #333;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.story-fullscreen h2 {
    margin-top: 0;
}

.score-input {
    margin-top: 1rem;
}

.score-input label {
    display: block;
    margin-bottom: 0.5rem;
    color: #ddd;
}

.score-input input {
    padding: 0.5rem;
    border: 1px solid #555;
    border-radius: 4px;
    background-color: #222;
    color: rgb(200, 200, 200);
}

.score-input button {
    margin-top: 0.5rem;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: rgb(200, 200, 200);
    cursor: pointer;
    transition: background-color 0.3s;
}

.score-input button:hover {
    background-color: #0056b3;
}

/* Stories list styling */
.stories-columns {
    display: flex;
    gap: 1rem;
}

.unjudged-stories,
.judged-stories {
    flex: 1;
    background-color: #333;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.unjudged-stories h2,
.judged-stories h2 {
    margin-top: 0;
}

.unjudged-stories ul,
.judged-stories ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.unjudged-stories li,
.judged-stories li {
    padding: 0.5rem;
    border-bottom: 1px solid #444;
    cursor: pointer;
    transition: background-color 0.3s;
}

.unjudged-stories li:hover,
.judged-stories li:hover {
    background-color: #444;
}

.unjudged-stories li:last-child,
.judged-stories li:last-child {
    border-bottom: none;
}
