.search-container {
    position: relative; /* Key to keeping the dropdown attached to the input field */
    margin: 8px;
}

.wide-search .search-input, .mobile-search .search-input {
    padding: .8rem;
    border: 1px solid #282c32;
    border-radius: 20px;
    font-size: 1rem;
    background-color: rgb(8, 8, 8);
    color: rgb(200, 200, 200);

    
}

.search-dropdown {
    position: absolute; /* Ensures the dropdown stays on top of the other content */
    top: 100%; /* Aligns the dropdown right below the input field */
    width:12.3rem;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 10; /* Ensures it appears on top of other content */
}

.search-dropdown-item {
    padding: 10px;
    cursor: pointer;
}

.search-dropdown-item:hover {
    background-color: #f1f1f1;
}

.search-dropdown-link {
    text-decoration: none;
    display: block;
    width: 100%;
    text-align: left;
}

@media(max-width: 500px){
    .search-container{
        margin-right: 1rem;
    }
    .search-input {
        width: 91%;
    }
    .search-dropdown{
        right: .2rem;
        width: 120%;
    }
    .search-dropdown-item {
        font-size: 1.5rem;
        width: 100%;
    }
}