/* Basic styling for the textarea */
.thought-page {
    margin-left: 4rem;
   
}


.thought-input {
    width: 300px; /* Initial width */
    height: 30px; /* Initial height, looks like an input box */
    padding: 10px;
    font-size: 1rem;
    transition: all 0.3s ease; /* Smooth transition effect */
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: none; /* Disable manual resizing */
    box-shadow: none;
    outline: none;
}

/* Expanded state when clicked (focused) */
.thought-input.expanded {
    width: 90%; /* Expands to full width */
    height: 150px; /* Expands to a paragraph-size height */
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* A slight shadow effect when focused */
}

.thought-text {
    padding: 1rem;
    color:white;
    font-weight: bold;
    border-radius: 10px;
    width: fit-content;
}

.thought-box {
    border-radius: 5px;
    border: 1px solid #007bff;
    background-color: #007bff;
    padding: 5px;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* A slight shadow effect when focused */
}
/* .thought-box:hover {
    background-color: #0257b1;
} */

#thought-name {
    color:rgba(25,28,36)
}
#thought-name:hover {
    color:gray;
}

@media(max-width: 500px){
    .thought-input {
        width: 200px;
    }
   
}