
.genre-checkboxes {
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* 5 columns for large screens */
    gap: 10px;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
    font-weight: 650;
}

.checkbox-item {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    border-radius: 20px;
    background-color:  rgb(8, 8, 8);
    color: rgb(200, 200, 200);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.2s ease;
    white-space: nowrap; /* Prevents text from splitting into multiple lines */
}

.checkbox-item input[type="checkbox"] {
    margin-right: 8px;
    transform: scale(1.2);
    accent-color: #3b82f6;
    cursor: pointer;
}

.checkbox-item:hover {
    background-color: #e8f0fe;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
    color:  rgb(8, 8, 8);
}

.checkbox-item input[type="checkbox"]:disabled + label {
    cursor: not-allowed;
    color: #bbb;
}

.checkbox-item input[type="checkbox"]:disabled {
    accent-color: #ddd;
    cursor: not-allowed;
}

.checkbox-item.disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.genre-checkboxes .checkbox-item:hover:not(.disabled) {
    background-color: #e8f0fe;
}


/* Additional styling for other components */
.story-input textarea {
    width: 60%;
    height: 400px;
    border-radius: .5rem;
    background-color: rgb(18, 18, 18);
    color: rgb(200, 200, 200);
}

.content-warning-options {
    margin-left: auto;
    margin-right: auto;
    max-width: 80%;
}

.prompt-div {
    background-color: rgb(28, 28, 28);
    color: white;
    padding: 2rem;
    border-radius: 1em;
    max-width: 60rem;
    font-size: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
}

.genres-div {
    margin: 4rem;
}

.guidelines {
    margin: 4rem;
    
}

.guidelines-text {
    background-color: rgb(18, 18, 18);;
    border-radius: 10px;
    padding: 1rem;
}

.title-input label {
    font-size: 1.5rem;
    margin-right: .5rem;
}

.title-input input {
    border-radius: .5rem;
    font-size: 2rem;
    background-color: rgb(18, 18, 18);
    color: rgb(200, 200, 200);
}

.story-input label {
    margin-right: .5rem;
}

.terms-link {
    text-decoration: underline;
    color: blue;
}

.terms-ratings {
    margin: 2rem;
    text-align: left;
    margin-left: 20%;
}

.terms-ratings label {
    font-size: 1.2rem;
}



@media (max-width: 1024px) {
    .genre-checkboxes {
        grid-template-columns: repeat(3, 1fr); /* 3 columns for tablets */
    }

    .story-input textarea {
        width: 80%;
    }
}

/* Mobile view: 2 columns */
@media (max-width: 768px) {
    .genre-checkboxes {
        grid-template-columns: repeat(3, 1fr); /* 2 columns for mobile */
        gap: 5px;
        font-size: .8rem;

    }
    .checkbox-item {
        padding: 4px 6px;
    }

    .story-input textarea {
        width: 90%;
  
    }
    
}