

.discover-list {
  height: fit-content;
  overflow-y: auto; /* Scrollable overflow */


}
.discover-list  {
    display: flex;
    flex-wrap: wrap;
    border-radius: 8px;
    padding: .5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: rgb(8, 8, 8);
    color: rgb(200, 200, 200);
    margin-bottom: 1rem;
    text-align: left;
    width:fit-content;
    align-items:center;
}

.discover-story {
  border: 1px solid #878787;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: rgb(28, 28, 28);
  color:rgb(200, 200, 200);
  margin-bottom: 1rem;
  text-align: left;
  width: 100%;
}


.discover-snippet {
    text-align: left;
    font-size: .8rem;
}

.discover-story .story-interaction span {
    padding-right: 7px;
    color:#ccc;
}

.discover-story .story-interaction {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
}

.discover-story:hover {
    cursor: pointer;
    border: 1px solid rgba(19, 53, 205, 0.9);
    background-color: rgb(18, 18, 18);
  }

.discover-genre-selector {
  background-color: rgb(28, 28, 28);
  padding: 1.5rem 0rem 1.5rem 0rem;
  border-radius: 1rem;
  margin: .6rem;

}

.discover-nav {
    display: flex;
    align-items:flex-start;
    border-bottom: 1px solid rgb(50, 50, 50, .5);
    height: fit-content;

  }

.discover-nav >div {
    padding: 1rem;
    cursor: pointer;
    font-size: 1.3rem;
    font-weight: 600;
}

  
  .discover-nav .active {
    color: white;
    border-bottom: 3px solid rgb(19, 53, 205);
  }
  
  /* Optional: Additional styles for hover effect */
  .discover-nav h3:hover {
    color: white;
  }

  .discover-list .story-details {
    display:inline-block;
  }

  .story-author {
    margin-left: 1rem;
  }
  .story-details-prompt {
    padding: 1rem;
    margin: 5px;
    text-align: center;
    background-color: rgb(8, 8, 8);
    color: white;
    border-radius: 10px;
    font-weight: 600;
  }

  .prompt-container p {
    padding: 10px;
  }


  .title-author-genres {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

  }

  .icons-chevron {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

  }

  .toggle-btn {
    justify-self: flex-end;
    margin-left: 1rem;
  }


  @media(max-width: 500px){
  

    .title-author{
      justify-content: center;
    }
    .title-author strong {
      padding: 4px;
    }
    .title-author-genres {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-around;
  
    }


    
  }