/* allStories.css */

.filter-section {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
}

.filter-section select {
    width: fit-content;
}

.filter-select, .genre-select, .search-input {
    padding: 10px 15px;
    border: 1px solid #007BFF;
    border-radius: 8px;
    font-size: 16px;
    background-color: #fff;
    transition: background-color 0.3s, border-color 0.3s;
}

.filter-select:hover, .genre-select:hover {
    background-color: #e2e6ea;
    border-color: #0056b3;
}

.stories-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 4rem;
}

.all-story {
    padding: 2rem;
    border: 1px solid #007BFF;
    border-radius: 8px;
    background-color:rgb(8, 8, 8);
    color:rgb(200,200,200);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, box-shadow 0.3s;
}

.all-story:hover {
    background-color: rgb(28, 28, 28);
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.storyTitle {
    font-size: 1.5rem;
    font-weight: bold;
    color: #007BFF;
    cursor: pointer;
    margin-right: 20px;
    transition: color 0.3s;
}

.storyTitle:hover {
    color: #0056b3;
}

.all-story p {
    margin: 1rem;
    font-size: 1rem;
    color: rgb(160, 160, 160);
}



.story-details {
    display: block;
    align-items: center;
    justify-content: space-around;

}

.snippet {
    text-align: left;

}

.all-story-prompt {
    max-width: 70%;
    background-color: rgb(18, 18, 18);;
    color: white;
    padding: 1rem;
    margin-left: 10%;
    margin-top: 1rem;
    border-radius: 10px;
}
.all-story .story-interaction span {
    padding-right: 7px;
    color: rgb(200, 200, 200)
}

.all-story .story-interaction {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
}

.read-next-page {
    margin: 3rem;
}

.read-next-page .reply-submit {
    margin-left: 5px;
}

.read-next-select {
    border-radius: 5px;
    padding: 3px;
}


.all-title-author-genres{
    display: flex;
    align-items: center;
}


@media(max-width: 970px){
    .filter-section {
        flex-wrap: wrap;
    }

    .all-story-prompt {
        max-width: 90%;
        margin-left: 0%;

    }
}