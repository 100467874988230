#forum-container {
    padding: 20px;
}

.forum-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.questions-list {
    width: 100%;

}

.forum-question {
    border-radius: 10px;
    background-color: rgb(8, 8, 8);
    color:rgb(200,200,200);

}

.username {
    color:#007bff;
    text-decoration: none;

}

.username:hover {
    color: #024791;
}
.forum-stats {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.new-question-section textarea, .comment-section textarea, .reply-section textarea {
    width: 80%;
    font-size: 1;
    margin-top: 8px;
}

.new-question-section, .reply-section, .comment-section {
    margin-bottom: 1rem;
}

.forum-comment .comment-top {
    background-color: rgb(8, 8, 8);
    color:rgb(200,200,200);
}
.forum-input {
    width: 100%;
    padding: 5px;
    margin-bottom: 10px;
    font-size: 1rem;
    border-radius: 10px;
    background-color: rgb(8,8,8);
    color: rgb(200,200,200);

}


.replies-section textarea {
    width: calc(100% - 60px);

}

.forum-top{
    display: inline;
}

.category-select {
    padding: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    border-radius: 10px;
    width: fit-content;
    background-color: rgb(8,8,8);
    color: rgb(200,200,200);
    margin: 5px;

}

.submit-button, .toggle-replies-button, .toggle-comments-button {
    padding: 10px;
    border: none;
    background-color: #007bff;
    color: rgb(200, 200, 200);
    cursor: pointer;
    width: fit-content;
    border-radius: 5px;

}

.question-button {
    padding: 13px;
    border: none;
    background-color: #007bff;
    color: rgb(200, 200, 200);
    cursor: pointer;
    border-radius: 10px;
}

.toggle-comments-button.active, .toggle-replies-button.active {
    background-color:#024791; /* Darker background when replies are visible */
}

.submit-button:hover, .toggle-comments-button:hover, .toggle-replies-button:hover {
    background-color: #0056b3;
}

.delete-button {
    padding: 10px;
    border: none;
    background-color: #ff0000;
    color: rgb(200, 200, 200);
    cursor: pointer;
    width: 4rem;
    border-radius: 10px;
}

.forum-buttons {
    display: flex;
    justify-content: space-between;
}

.forum-like-comment {
    display: flex;
}

.forum-like-button {
    padding: 10px;
    border: none;
    background-color: #007bff;
    cursor: pointer;
    width: fit-content;
    margin-right: 5px;
    border-radius: 5px;
}




.delete-button:hover {
    background-color: #c82333;
}

.questions-list, .replies-list, .comments-list {
    margin-top: 20px;
}

.forum-question {
    border: 1px solid rgb(200,200,200);
}

.forum-question, .forum-reply, .forum-comment {
    padding: 10px;
    margin-bottom: 10px;
    background-color: rgb(8, 8, 8);
    color:rgb(200,200,200);
    text-align: left;
    
}

.forum-question p, .forum-reply p, .forum-comment p {
    padding-right: 10px;
}

.forum-top {
    margin: 0rem 1rem 1rem 1rem;
}


.delete-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.delete-popup-content {
    background: white;
    border-radius: 5px;
    text-align: center;
}

.delete-popup button {
    margin: 0 10px;
}


.forum-comment  {
    margin-left: 5rem;
}

.forum-text {
    margin: 2rem;
    font-weight: 660;

}


.comment-top {
    background-color: rgb(8, 8, 8);
    color:rgb(200,200,200);
    padding: .5rem;
    border-radius: 10px;
    margin-left: 4rem;
}


.reply-date {
    font-size: small;
}

.search-forum .search-input {
    width: 15rem;
    padding: .8rem;
    border: 1px solid rgb(8, 8, 8);
    border-radius: 20px;
    font-size: 1rem;
    background-color: rgb(28, 28, 28);
    color: rgb(200, 200, 200);
}


.search-forum {
    position: relative;
    display: inline-block;
}


.search-dropdown {
    position: absolute;
    top: 100%; /* Place directly below the input */
    left: 0;
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 8px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
    background-color: rgb(8,8,8);
    color: rgb(200,200,200);
}

.search-dropdown-item {
    padding: 10px;
    border-bottom: 1px solid #eee;
    background-color: rgb(8,8,8);
    color: rgb(200,200,200);
    cursor: pointer;
    transition: background-color 0.3s;
}

.search-dropdown-item:hover {
    background-color: rgb(28,28,28);
}

.search-dropdown-link {
    color: rgb(200,200,200);
    text-decoration: none;
    font-size: 0.9rem;
}

.search-dropdown-link:hover {
    text-decoration: underline;
}

.reply-top{
    color: rgb(200, 200, 200);
    background-color:rgb(28,28,28);
    padding: .5rem;
    border-radius: 10px;
    margin-left: 4rem;
    margin-bottom: 10px;
}


@media(max-width: 700px){
    .forum-comment, .reply-top  {
        margin-left: 1rem;
    }
    .toggle-replies-button, .toggle-comments-button {
        width: 8rem;
    }

}