/*PROMPT GENERATORS*/

#prompts {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10rem;

  }

.prompts-home-description p {
  text-align: left;
  padding: .4rem;
}
  #promptHome {
    margin-top: 1.5rem;
    font-size: 1.2rem;
    background-color: rgb(18, 18, 18);;
    color: rgb(200, 200, 200);
    border-radius: 1em;
    padding-bottom: 4rem;
    border: 2px solid #007bff;
  
  }
  
  
  
  .lineNav {
    display: flex;
    font-size: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .lineNav .link {
    padding: 1rem;
    color:aliceblue;
  }
  
  .lineNav .link.active {
    text-decoration: underline;
  }
  
  .lineNav .link:hover {
    cursor:pointer;
    color: gray
  }
  
  .link {
    color: aliceblue;
    text-decoration: none;
    font-weight: 600;
  }
  
  
  .promptHeader:hover {
    cursor:pointer;
    color: gray
    
  }
  
  
  
  
  
  /*PROMPTS*/
  
  
  .removeSaved {
    font-size: .6rem;
  }
  
  .removeSaved:hover {
    cursor:pointer;
  }
  
  .prompt {
    max-width: 80%;
    font-size: 2rem;
    list-style: none;
    
  }
  
  .prompt-subsection, #plotP, #plotPSave  {
    margin-bottom: 4rem;
    color:rgb(18, 18, 18);;
    border-radius: 1em;
  
  }
  
  .savedPrompt p {
    margin-bottom: 1rem;
  }
  
    
    .prompt button, #button1, #button2, #button3  {
      align-items: center;
      appearance: none;
      background-color: #fff;
      border-radius: 24px;
      border-style: none;
      box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
      box-sizing: border-box;
      color: #3c4043;
      cursor: pointer;
      display: inline-flex;
      fill: currentcolor;
      font-family: "Google Sans",Roboto,Arial,sans-serif;
      font-size: 1rem;
      font-weight: 500;
      height: 3.2rem;
      justify-content: center;
      letter-spacing: .25px;
      line-height: normal;
      max-width: 100%;
      overflow: visible;
      padding: 1rem;
      text-align: center;
      text-transform: none;
      transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1),opacity 15ms linear 30ms,transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;
      width: auto;
      font-weight: bold;
      text-decoration: none;
    }
    
    .prompt button:hover , #button1:hover, #button2:hover,#button3:hover {
      background: #F6F9FE;
      color: #174ea6;
    }
    
    .prompt button:active , #button1:active, #button2:active, #button3:active {
      box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%), 0 8px 12px 6px rgb(60 64 67 / 15%);
      outline: none;
    }
  
    .prompt button.active , #button1.active, #button2.active, #button3.active {
      border: 2px solid #4285f4;
    }
    
    .prompt button:focus, #button1:focus, #button2:focus, #button3:focus  {
      outline: none;
      border: 2px solid #4285f4;
    }
    
    .prompt button:not(:disabled), #button1:not(:disabled), #button2:not(:disabled),#button3:not(:disabled) {
      box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
    }
    
    .prompt button:not(:disabled):hover , #button1:not(:disabled):hover, #button2:not(:disabled):hover,#button3:not(:disabled):hover{
      box-shadow: rgba(60, 64, 67, .3) 0 2px 3px 0, rgba(60, 64, 67, .15) 0 6px 10px 4px;
    }
    
    .prompt button:not(:disabled):focus , #button1:not(:disabled):focus, #button2:not(:disabled):focus,#button3:not(:disabled):focus{
      box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
    }
    
    .prompt button:not(:disabled):active , #button1:not(:disabled):active, #button2:not(:disabled):active,#button3:not(:disabled):active{
      box-shadow: rgba(60, 64, 67, .3) 0 4px 4px 0, rgba(60, 64, 67, .15) 0 8px 12px 6px;
    }
    
    .prompt button:disabled , #button1:disabled, #button2:disabled, #button3:disabled{
      box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
    }
  
  
    
  
  #button2 {
    font-size: 1.1rem;
    height: 2rem;
    padding: 1.3rem;
    text-decoration: none;
  
  
  }
  
  #button3 {
    width: 7.5rem;
  
  }
  
  
  /*PLOT PROMPT */
  
  #plotPrompt {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 1rem;
  }
  
  #button2 {
    margin-top: .5rem;
  }
  
  #plotP {
    max-width: 60rem;
    font-size: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;

  }

  #plotPSave {
    max-width: 60rem;
    font-size: 2rem;
    margin-top: 2rem;
    margin-bottom: 0px;
  }
  
  
  #showAll {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  
  #showAll .genre {
    width: 12rem;
  }
  
  
  #showButton {
    font-size: 1rem;
  }
  
  #showButton:hover {
    cursor: pointer;
  }
  
  


  
  .text b {
    cursor: pointer;
    transition: color .5s;
    
  
  }
  
  .text b span {
    display: none;

    
  }
  
  .text b:hover {
    color: gray;
    
  }
  
  
  .text b:hover span {
    cursor: pointer;
    display: block;
    position: fixed;
    left: 50%; /* Center the tooltip horizontally */
    bottom: 10%; /* Add some space from the bottom */
    transform: translateX(-50%); /* Center alignment */
    width: 70%; /* Slightly wider tooltip */
    padding: 0.75rem; /* Increase padding for better touch targets */
    color: #333; /* Darker text color for better contrast */
    font-weight: 600; /* Slightly bolder text */
    background: rgba(229, 229, 229); /* Light background with slight transparency */
    text-align: left;
    border: 3px solid #666;
    border-radius: 1rem; /* More rounded corners for a modern feel */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    font-size: 1rem; /* Slightly smaller font size for cleaner look */
    transition: all 0.3s ease; /* Smooth transition for hover effects */
    z-index: 1000; /* Ensure it's above other content */
    font-size: 1.2rem;
}

.text b:hover span::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 100%; /* Position above the tooltip */
    margin-left: -6px; /* Center the arrow */
    border-width: 6px; /* Arrow size */
    border-style: solid;
    border-color: transparent transparent #e5e5e5 transparent; /* Arrow color */
}


  
  .text b:hover span h3{
    text-align: center;
  }
  
  
  
  
  #seeAllBox  {
  margin-top: 100px;
  font-size: 1rem;
  border: 2px solid white;
  border-radius: 1rem;
  
  
  }
  
  #seeAllBox p {
    padding: 1rem;
  letter-spacing: 1px;
  }
  
  #seeAllBox p:hover {
    color:#666;
    cursor: pointer;
  }
  

  
  #seeAllBox a {
    text-decoration: none;
    color: white
  }
  
  
  /*ALL*/


  /* sideNav container */
  .sideNav {
    width: 9rem;
    position: fixed;
    left: auto;
    top: 13.5rem;
    right: 6rem; 
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    
  }

  @media(max-width: 1100px){
    .sideNav {
      top:15.5rem;
    }
  }
  .sideLink {
    float: left;
    
  }


  /* Dropdown content (hidden by default) */
  .sidedown-content {
    display: none;
    z-index: 1;
  }

  /* Show the sidedown menu on hover */
  .sideNav:hover .sidedown-content {
    display: block;
  }

  .sideNav #button3{
    margin: .1rem;
    padding: 2px;
  }





  
  #all {
    display: flex;
    flex-wrap: wrap;
  }
  
  
  .mainAll {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
  }
  
  
  #top {
    font-size: .8rem;
    color: rgb(38, 145, 238);
  }
  
  #top:hover {
    color: gray;
  }
  
  .genres, .plots, .personalities, .characters, .arcs, .show {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  
  }
  
  #genres {
    padding: 0px 1rem 0px 1rem;
  }
  
  
  .genre, .plot, .personality, .character, .arc {
    font-size: 1rem;
    width: 18rem;
    justify-content: space-between;
    justify-content: center;
    background-color: rgb(200, 200, 200);
    color: rgb(18, 18, 18);;
    text-align: left;
    padding: .5rem;
    border-radius: 25px;
    margin: .5rem;
  
  }
  
  
  
  
  .genre h3, .plot h3, .personality h3, .character h3, .arc h3, .arc1 h3{
    
    text-align: center;
    border-bottom: 1px solid rgb(18, 18, 18);;;
  
  }
  
  
  
  
  /*OTHER PROMPTS*/
  

  #loading {
    animation-duration: 3s;
    animation-name: App-logo-spin;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    font-size: 4rem;
    color: rgb(200, 200, 200);
  }
  

 
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  #premisePrompt {
    max-width: 60%;
  }
  
  
  #redditLink {
    margin-left: 2rem;
    font-size: 1rem;
    color: rgb(38, 145, 238);
  
  }
  
  #randomPrompt p{
    text-align: left;
  }
  
  footer {
    height: 3rem;
    text-align: center;
    margin-top: 10rem;
  }
  
  #footerLogo {
    width: 5rem;
  }



  @media (max-width: 500px) {


    #plotP, #plotPSave, #premisePrompt {
      max-width: 40rem;
    }

    .genre, .plot, .personality, .character, .arc {
        font-size: 1.5rem;
        width: 30rem;
        justify-content: space-between;
        justify-content: center;
        background-color: rgb(200, 200, 200);
        color: rgb(18, 18, 18);;
        text-align: left;
        padding: .5rem;
        border-radius: 25px;
        margin: .5rem;
      
      }
      
  }