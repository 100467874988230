body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  position: relative;
  background-color: rgb(18, 18, 18);;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  color: rgb(200, 200, 200);
  text-align: center;

}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

*/


.stylesToKeep {

  color: #007bff;
 
  color: rgba(11, 48, 212);
  background-color: rgb(8, 8, 8);
  background-color: rgb(18, 18, 18);
  background-color: rgb(28, 28, 28);



}



header {
  margin-top: 5rem;
}

ul {
  text-align: left;
}

input, textarea {
  font-family: 'Gill Sans';
  font-weight: 600;
}

.page h1 {
  font-size: 2.2rem;

}

.page {
  display: flex;
  justify-content: center;
  background-color:rgba(8,8,8,.9);
}

.content {
  width: 90%;
  border-radius: 1rem;
}

#error-page {
  height: 40rem;
  font-family: 'Permanent Marker';
}




/* Loading screen styles */
.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color:rgba(8,8,8,.95);
  z-index: 1000;
}

.prof-loading-screen {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color:rgba(18,18,18,.95);
  z-index: 1000;
}

.loading-container {
  position: relative;
 
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-image {
  position: absolute;
  height: auto;
  object-fit: contain;
  animation: waddle 2s infinite;
}

.loading-overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  transition: transform 1s ease-in-out;
  transform: translateY(100%);
  z-index: 0;
}

.loading-screen.show .loading-overlay {
  transform: translateY(0);
}

/* Waddle animation */
@keyframes waddle {
  0% {
      transform: rotate(0deg);
  }
  25% {
      transform: rotate(5deg);
  }
  50% {
      transform: rotate(0deg);
  }
  75% {
      transform: rotate(-5deg);
  }
  100% {
      transform: rotate(0deg);
  }
}


@media (max-width: 1100px) {
  
}

@media (max-width: 700px) {
  html {
    font-size: 14px;
  }
  .mainOutlet {
    background-image: none;
  }
}

  @media (max-width: 500px) {

      html {
        font-size: 12px;
      }
    .leftSide, .rightSide {
      width: 0px;
      
    }
    
    .content {
      width: 97.5%;
    }
    

  }

