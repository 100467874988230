
.account-form-container {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: #333;
  width: 65%;
  margin: 6rem 2rem;

}

.account-header {
  text-align: center;
  margin-bottom: 20px;
}

.account-form {
  display: flex;
  flex-direction: column;
  
}

.account-field {
  margin-bottom: 15px;
  margin-right: 2rem;
  margin-left: 2rem;

}

.account-field label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.toggle-section span:hover {
  cursor: pointer;
}

.account-field input,
.account-field textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.success-message {
  color: green;
  margin-top: 10px;
}


button:disabled {
  background-color: #ccc;
}

.submit-button {
  border-radius: 5px;
}
.delete-account-button {
  padding: 10px;
    border: none;
    background-color: #ff0000;
    color: rgb(200, 200, 200);
    cursor: pointer;
    width: 6rem;
    margin-bottom: 10px;
    border-radius: 10px;
  
}


.confirmation-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.confirmation-popup-content {
  background-color: #333;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.confirm-delete-button {
  background: red;
  color: rgb(200, 200, 200);
  border: none;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.cancel-button {
  background: grey;
  color: rgb(200, 200, 200);
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}