

.footer {
    padding: 2rem 0 2rem 0;
    background-color: rgb(8, 8, 8);
}

.icons {
    display: flex;
    justify-content: center;
}

.icons img:hover {
    opacity: 60%;
    cursor: pointer;
}
.footer img {
    width: 4rem;
    background-color: rgb(200, 200, 200);
    margin: 5px;
    border-radius: 20%;

}


.footerLink {
    color: rgb(200, 200, 200);
    text-decoration: none;
    padding: .3rem 1.5rem ;
}

.footerLink:hover {
    color: gray;
}

.footerList {
    display: flex;
    flex-wrap: wrap;
    width: 9rem;
    padding-left: 0rem;
    justify-content: flex-start;
    text-align: left;
}

#footerLists {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

}


@media (max-width: 700px) {
    #footerLists {
        font-size: larger;
    }
}