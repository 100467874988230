
  
  .page main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
  }
  
  .register-container {
    width: 100%;
    max-width: 500px;
    color: #333;

  }
  
  .register-form-container {
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .register-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .register-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .register-field label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .register-field input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .error-message {
    color: red;
    font-weight: bold;
  }
  
  
  
  .login-link {
    text-align: center;
    margin-top: 10px;
  }
  
  .login-link a {
    text-decoration: underline;
    font-weight: bold;
  }
  