.open-contests-carousel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    padding: 2rem .5rem 2rem .5rem;
    background-color: rgb(8, 8, 8);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.open-contest {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: rgb(28, 28, 28);
    color: rgb(200, 200, 200);
    transition: border 0.3s;
}

.open-contest #prize-img {
    align-self: center;
    background-color: rgba(40, 44, 52);
    border-radius: 50%;
    margin-top: .5rem;
    width: 200px;


}

.open-contest:hover {
    cursor: pointer;
    border: 2px solid rgba(19, 53, 205, 0.9);
}

.open-contests-prev,
.open-contests-next {
    border-radius: 50%;
    color:rgba(25,28,36);    
    font-size: 40px;
    height: 40px;
    line-height: 35px;
    text-align: center;
    width: 40px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.open-contests-prev:hover,
.open-contests-next:hover {
    background: rgba(0, 0, 0, 0.8);
    color: #aaaaaa;
}

@media(max-width: 1100px){
    .open-contest #prize-img {
        width: 150px;
    }
}

@media(max-width: 700px){
    .open-contest #prize-img {
        width: 100px;
    }
  
}