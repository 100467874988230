.carousel {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .carousel-item {
    position: relative;
    flex-grow: 1;
  }
  
  .blogTop {
    position: relative;
  }
  
  .blog-carousel-image {
    width: 100%;
    display: block;
    border-radius: 10px;
    height: 25rem;

  }
  
  .blog-carousel-header {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6); /* Slight background for text readability */
    color: rgb(200, 200, 200);
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 10px;

  }
  
  .blogTitle {
    font-size: 24px;
    font-weight: bold;
  }
  
  .blogHook {
    font-size: 16px;
  }
  
  .carousel-prev, .carousel-next {
    border-radius: 50%;
    color: rgb(200, 200, 200);   
    font-size: 40px;
    height: 40px;
    line-height: 35px;
    text-align: center;
    width: 40px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .carousel-prev:hover, .carousel-next:hover {
    color: gray;
  }


  @media(max-width: 500px){
    .blogImage {
      width: 100%;
      height: 25rem;
    }

    .carousel-prev, .carousel-next {
      color: rgb(200, 200, 200);
      
    }
  }