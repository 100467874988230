#prof-header {
    margin: 1rem 0rem 1rem 0rem;
    border-radius: 1rem;
    transition: background 2s ease; /* Smooth transition for background change */
  }
  
  #profHead {
    text-decoration: none;
    color: #f4f4f9;
  }
  
  #profHead h1 {
    margin: 0;
    padding: 1rem;
  }
  

  #profile-img {
    max-width: 100px;
    border-radius: 10px;
    
  }

  #pen-name {
    font-size: 3rem;
    font-family: "Permanent Marker";

  }

  #prof-username {
    text-decoration: none;
    color: #f4f4f9;

  }

  #prof-username:hover {
    color:rgba(25,28,36,.95);
    }

    #statline {
      font-size: large;
      word-spacing: 10px;
    }

    #follow {
      position:relative;
      left: 18.4%;
      font-size: larger;
    }

    #follow:hover {
      cursor: pointer;
      color: #007bff;
    }


    @media(max-width: 900px){
      #follow {
        left:20%;
        font-size: 1.4rem;
      }
    }

    @media(max-width: 500px){
      #follow {
        left:50%;
        font-size: 1.8rem;
      }
    }