/* story.css */

#story-page {
    color: #fff;
    display: flex;
    justify-content: center;
}

#full-story {
    padding: 20px;
    border-radius: 10px;
    font-size: 1.2rem;
}

#story-page-prompt {
    color: rgb(200, 200, 200);
    background-color: rgb(18, 18, 18);
    padding: 2rem;
    border-radius: 1rem;
    font-size: 2.1rem;
}

#indyStory p {
    text-align: left;
    line-height: 1.8;
}

#indyStory #heart:hover {
    color: rgb(193, 3, 3);
    cursor:pointer;
}


.story-like-button {
    background-color: #444;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    margin-right: 10px;
    width: fit-content;
}

.story-like-button:hover {
    background-color: #555;
}


.rating-info {
    margin-left: 10px;
    color: #ddd;
}

.story-comments-section {
    margin-top: 20px;
    text-align: left;
}



.toggle-story-comments {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    width: fit-content;
    margin-top:5px;
    margin-bottom: 1rem;
    
}

.toggle-story-comments:hover {
    cursor: pointer;
    background-color: #0059b9;
}

.story-comment-input, .reply-input {
    width: calc(100% - 60px);
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
    margin-top: 10px;
    height: 2rem;
}

.comment-text {
    margin-left: 2rem;
}

.story-comment-submit, .reply-submit{
    background-color: #444;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    width: fit-content;
    margin: 5px;
}

.toggle-story-replies {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    width: fit-content;
    margin-top:5px;
}

.toggle-story-replies.active, .toggle-story-replies:hover {
    background-color: #0059b9; /* Darker background color when replies are visible */
}

.story-comment-submit:hover, .reply-submit:hover {
    background-color: #555;
}

.story-comments {
    margin-top: 10px;
}

.story-comment {
    background-color: #333;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    color: rgb(200, 200, 200);
}

.comment-delete-button {
    background-color: #ff0000;
    color: rgb(200, 200, 200);
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    width: fit-content;
    margin-right: 4px;
}

.comment-delete-button:hover {
    background-color: #b80404;
}



.story-reply {
    background-color: #444;
    padding: 5px;
    border-radius: 5px;
    margin-left: 20px;
    margin-top: 5px;
    color: rgb(200, 200, 200);
}

.reply-section {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-left: 20px;
}

.reply-section input {
    width: calc(100% - 60px);
}

.tagged-user {
    color: #ffc107;
    font-weight: bold;
    text-decoration: none;
}

#commenter-name {
    text-decoration: none;
    color: #007bff;
}

#commenter-name:hover {
    color:#222;
}

#full-story.light-mode {
    background-color: rgb(200, 200, 200);
    color:#333
}

#full-story.dark-mode {
    background-color:rgba(8,8,8);
    color: rgb(200, 200, 200);
}

.story-controls {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;
}

.light-mode-button {
    background-color: rgb(200, 200, 200);
    color: #333;
    padding: 0.5em 1em;
    cursor: pointer;
    width: 7rem;
    border-radius: 0px;
}

.dark-mode-button {
    background-color:rgba(8,8,8);
    color: rgb(200, 200, 200);
    padding: 0.5em 1em;
    cursor: pointer;
    width: 7rem;
    box-shadow: none;
}

.font-size-control {
    display: flex;
    align-items: center;
}



.tagged-username {
    color: #ffc107;
    text-decoration: none;
}

#font-button-plus {
   
    padding: 5px;
}
#font-button-minus {
    
    padding: 5px;
}

#font-button-minus:hover, #font-button-plus:hover {
    cursor: pointer;
}

#font-label {
    
    padding: 5px;
}

.story-interaction .selected {
    color: #ffeb3b;

}

#ratings-stars:hover {
    cursor: pointer;
}

#story-rating {
    padding: 1rem;
    text-align: left;
}




#indy-story #heart {
text-align: right;

}

.story-toggle-replies {
    background-color: #333;
    border: none;
    color: rgb(200, 200, 200);
}



.flagging {
    margin-top: 20px;
    text-align: left;
}

.flagging label {
    margin-right: 10px;
}

.flagging select {
    margin-right: 10px;
}

.flagging button {
    background-color: #ffcc00;
    border: none;
    color: rgb(200, 200, 200);
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
}

.flagging button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}



#flag-type {
    padding: 3px;
    border-radius: 5px;
}


.share-button {
    margin: 3px;
    font-size: 2rem;
}

.share-button:hover {
    color: gray;
}

.favHeart {
    margin: 1rem;
    font-size: 1.5rem;
}

.fixed-bookmark-button {
    position: fixed;
    bottom: 50px; /* Position at the bottom */
    right: 16px; /* Position to the right */
    z-index: 100; /* Ensure it is above other elements */
    background-color: rgb(18, 18, 18,.0);
    color: rgb(200, 200, 200); /* Text color */
    padding: 10px; /* Padding for the button */
    font-size: 1rem; /* Font size for readability */
    border: none; /* Remove default borders */
    cursor: pointer; /* Change cursor on hover */
    border-radius: 0 10px 10px 0; /* Rounded corners on the right */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow for depth */
    
    /* Create a triangle-like pointed left side */
    clip-path: polygon(10px 0, 100% 0, 100% 100%, 10px 100%, 0 50%);
}

.fixed-bookmark-button:hover {
    color:#007bff;
}
#full-story {
    padding: 1rem; /* Add some padding for mobile */
    max-width: 100%;
    overflow: hidden; /* Prevent overflow */
    box-shadow: 5px 5px 10px rgba(208, 207, 215, 0.1);
}

.pagination {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

#story-page {
    overflow: hidden; /* Prevent overflow for mobile */
}

.read-next-select {
    width: fit-content;
}

.select-feature select{
    width: fit-content;

}

.story-comments-section input{
    width: 90%;
    height: 2rem;
}

@media(max-width: 1000px){
    .fixed-bookmark-button {
        right:10px;
    }

}

@media(max-width: 500px){

    .story-like-button {
        padding: 5px;
    }
    .rating-info {
        margin-left: 5px;
    }
    #story-rating {
        padding: .5rem;
    }
    #full-story {
        padding: 5px;
        width: 100%;
        margin: 0rem;
    }
    #indy-story {
        padding: 5px;
    }
    #story-page{
        padding: 0px;
    }

    .fixed-bookmark-button {
        right: 5px; /* Position to the right */
        padding: 10px 12px; /* Padding for the button */        
    }
    
}