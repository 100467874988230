.about-container {
    display: flex;
    flex-wrap: wrap; /* Allow content to wrap */
    margin-top: 20px;
}

.about-left {
    flex: 1;
    min-width: 300px; /* Minimum width for responsive design */
    margin-right: 20px; /* Space between image and text */
}

.about-genres {
    display: flex;
    flex-wrap: wrap;
    width: 100%; /* Responsive image */
    border-radius: 10%; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.about-right {
    flex: 2; /* Take more space than the left */
    display: flex;
    flex-direction: column;
}

.aboutDiv {
    margin-bottom: 20px;
    padding: 15px;
    background-color: #f9f9f9; /* Light background */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.aboutDiv h2 {
    margin-bottom: 10px;
    color: #333; /* Darker color for headings */
}

.aboutDiv p, .aboutDiv ul {
    color: #555; /* Softer color for text */
}
.aboutDiv p {
    text-align: left;
}

/* Adjusts list styles for ranking system and features */
.aboutDiv ul {
    list-style-type: disc; /* Use bullet points */
    padding-left: 20px; /* Indent list items */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .about-container {
        flex-direction: column; /* Stack on smaller screens */
    }

    .about-left {
        display: none;
    }
}






.ranks-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    background-color: #f7f7f7;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.ranks-list-item {
    padding: 15px;
    margin: 0;
    background-color: #ffffff;
    border-bottom: 1px solid #e0e0e0;
    color: #333;
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-size: 0.95rem;
    font-family: Arial, sans-serif;
    transition: background-color 0.2s ease;
}

.ranks-list-item:last-child {
    border-bottom: none;
}

.ranks-list-item strong {
    color: #333;
    font-size: 1rem;
}

.ranks-list-item:hover {
    background-color: #f0f4ff;
}

.ranks-list-item:nth-child(odd) {
    background-color: #f9fafb;
}

.ranks-list-item span {
    font-weight: normal;
    color: #555;
    font-size: 0.9rem;
}

/* Optional styling for additional valor text */
.ranks-list-item span.valor {
    font-style: italic;
    color: #666;
    font-size: 0.85rem;
}
