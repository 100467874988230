
.admin-news h1 {
    font-size: 1.8rem;
    color: #333;
    text-align: center;
    margin-bottom: 1.5rem;
}

.form-group {
    margin-bottom: 1.25rem;
}

.form-group label {
    display: block;
    font-weight: 600;
    color: #555;
    margin-bottom: 0.5rem;
}

.form-group input[type="text"],
.form-group textarea {
    width: 90%;
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.2s;
}

.form-group input[type="text"]:focus,
.form-group textarea:focus {
    border-color: #007bff;
    outline: none;
}

.form-group textarea {
    resize: vertical;
    min-height: 120px;
}

.form-group input[type="file"] {
    font-size: 0.95rem;
    color: #555;
}

button[type="submit"] {
    width: 100%;
    padding: 0.75rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s;
}

button[type="submit"]:hover {
    background-color: #0056b3;
}

button[type="submit"]:disabled {
    background-color: #aaa;
    cursor: not-allowed;
}

button[type="submit"]:disabled:hover {
    background-color: #aaa;
}
