.flagging {
    position: relative;
    font-family: Arial, sans-serif;
    margin: 20px;
    width: fit-content;
}

.flag-label {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    border-radius: 4px;
    color: rgb(152, 6, 6);
}


.flag-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 120%;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    padding: 10px;
    z-index: 10;
    display: flex;
    flex-direction: column;
}

.flag-dropdown select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    margin-bottom: 10px;
    outline: none;
}

.flag-dropdown button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.flag-dropdown button:hover {
    background-color: #0056b3;
}

.flag-dropdown button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}
