.follow-list {
    margin: 2rem;
    width: 95%;
    display: flex;
    flex-wrap: wrap;
}

.user-card {
    margin-bottom: 1.5rem;
}
.following-info:hover {
    background-color: gray;
}

.following-info {
    display: flex;
    flex-wrap: nowrap;
    background-color: rgb(200, 200, 200);
    color: rgb(18, 18, 18);;
    border-radius: 10px;
    padding: 5px;
    min-width: 180px;
    height: 80px;
    margin: .5rem;
    font-size: .9rem;
}

.prof-location {
    font-weight: 600;
}

.follow-user-link{
    color: rgb(18, 18, 18);;
    text-decoration: none;
}

.follow-container .search-input {
    width: 15rem;
    padding: .8rem;
    border: 1px solid #282c32;
    border-radius: 20px;
    font-size: 1rem;
    background-color: #282c32;
    color: rgb(200, 200, 200);
}