
  

  .prof-stories {
    color: rgb(200, 200, 200);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .prof-story {
    display: block;
    
  }
  .prof-snippet {
    text-align: left;
    color:rgb(200, 200, 200);;
    padding: 1rem;
  }

  #thumb {
    color: blue;
  }

  #eye {
    color: gray;
  }

  

  .prof-stories > div {
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: rgb(8, 8, 8);
    color: rgb(200, 200, 200);  }
  
  .prof-stories h2 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .prof-stories h3 {
    font-size: 1.2rem;
  }
  
  .prof-prompt {
    font-size: 1rem;
    color: white;
    background-color: rgb(28, 28, 28);
    padding: 1rem;
    border-radius: 10px;
    font-weight: 600;

  }
  
  .storyTitle:hover {
    cursor: pointer
  }

  .prof-stories .story-interaction {
    display: flex;
    justify-content: space-between;
}

.story-interaction >div {
  display: flex;
  align-items: center;
  gap: 8px;}


.prof-story:hover {
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.story-editor {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  width: 95%;
}

.story-edit-textarea {
  width: 100%;
  padding: 1rem;
  font-size: 1rem;
  border-radius: 8px;
  border: 1px solid #ccc;
  resize: vertical;
  margin-bottom: 0.5rem;
}

.edit-story-button {
  margin-right: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: rgb(200, 200, 200);
  background-color: #4a90e2;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.edit-story-button:hover {
  background-color: #357abd;
}

.delete-popup-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  color: black;
}

.delete-story-button {
  background-color: #444;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  width: fit-content;
  margin: 5px;
  background-color: red;
  align-self: center;
  justify-self: center;

}

.delete-story-button:hover {
  background-color:rgb(141, 4, 4);
  
}
