
.home{
  display:flex;
  flex-wrap: nowrap;
  justify-content: center;
  width: 100%;
  background-color: rgb(8, 8, 8, .95);
  align-items: flex-start;
}

.center {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;    
  background-color: rgb(8, 8, 8);
  border: 1px solid rgb(50, 50, 50, .5);
  width: 64%;
  flex: 1;
  overflow-y: auto; /* Allow vertical scrolling for center */
}

.right {
  text-align: left;
  width: 36%;
  position: sticky;
  top: 0; /* Stick to the top when scrolling */
  overflow-y: auto; 

}



.quote {
  color: rgb(200, 200, 200);
  border-radius: 10px;
  
}

.quote p {
  font-family: 'Permanent Marker';

}

.quote a {
  color: rgba(11, 48, 212);
  text-decoration: none;
  font-size: 70%;
}
.quote a:hover {
  color: gray;
}

.mobile-title {
display: none;
}

/*TICKER */

/* AdminActivity.css */

.home-ticker {
  width: 100%;
  overflow: hidden; /* Ensure content does not overflow the container */
  background-color: rgb(8, 8, 8);
}


.home-potd {
  background-color: rgb(28, 28, 28);
  color:white;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  margin: .5rem;
}
#home-potd-header:hover {
color: #0056b3;
}

.potd-info {
  margin: 6px;
}

.home-ticker .ticker {
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  font-size: 10px;
}

.home-ticker .ticker b{
  font-weight: 800;
}


.home-ticker .ticker-content {
  display: flex; /* Change to flex for easier handling of multiple items */
  white-space: nowrap; /* Ensure content stays on one line */
  animation: ticker-scroll 160s linear infinite; /* Adjust duration as needed */

}

.home-ticker .admin-activity-line {
  display: inline;
  margin-right: 20px; /* Space between items */
  margin-top:  0px;
  margin-bottom:  0px;
  padding: 0px;
  font-size: 1rem;
  color: rgb(200, 200, 200);
}


.home-ticker .link {
  text-decoration: none;
}

.link:hover {
  color: gray;
}

@keyframes ticker-scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.stream-p {
  font-family: 'Permanent Marker';
  color: rgba(11, 48, 212);

}



.search-section {
margin-bottom: 1rem;
text-align: left;
}

.sub-section {
  margin-bottom: 0 .2rem 3rem .2rem;

}

.leaderboard {
border-radius: 8px;
padding: 0px;
}


.quote {
padding: 8px;
margin: 3rem .3rem .3rem .3rem;
display: flex;
}


.get-started .navLink {
  text-decoration: none;
}


.home-subheader {
  color: white;
  margin-top: 0px;
  margin-bottom: 0;
  font-size: 1.4rem;
  padding: 1rem;
}


.blog-title {
font-size: 6rem;
padding: 4rem;
font-weight: 650;
text-align: left;
}

.blog-title span {
color:#0056b3;
}


#news-title {
text-align: right;
margin-top: 12rem;
}

#contests-title {
margin-bottom: 12rem;

}

#mobile-title {
  margin-top: 2rem;
}




@media (max-width: 900px){


  #mobile-title {
    display: block;
  }

  #quote {
    font-size: 1.5rem; /* Adjust font size if needed */
    text-align: center;
    padding: 5px;
  }

  .home{
    display:block;
    overflow-y: auto; 

  }

  .center {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;    
    background-color: rgb(8, 8, 8);
    border: 1px solid rgb(50, 50, 50, .5);
    flex: 0;
    overflow-y: auto; /* Allow vertical scrolling for center */
    width: auto;

  }

  .right {
    text-align: left;
    position:static;
    top: auto; /* Stick to the top when scrolling */
    overflow-y: auto; 
    width: 99%;
    text-align: center;

  }

  .sub-section {
    margin: 3px;
  }



}


@media (max-width: 500px) {

  .potd-prompt-home, .leaderboard {
    font-size: 1.2rem;
  }


  #quote {
    height: auto;
    width:auto;
    padding: 3rem 0rem 3rem 0rem;
  }

 

}


