
.plotArchs-page {
    width: 97%;
}



.plotArchs-page p, .plotArchs-page li, .arcs-page p {
    text-align: left;
  }
  
  .plotArchs-page p{
  font-size: 1.3rem;
  }
  
  
  .plotArchs-page h3 {
    color:#007bff;
   
  }

  .plotArchs-page h4 {
    margin-left:5%;
   
  }

  .card-heading {
    margin-left: 5%;
  }
  
  .archsNav ul{
    display: flex;
    flex-wrap: wrap;
    font-size: 1.2rem;
    justify-content: space-around;

  }

  .archsNav .link, .arcsNav .link {
    padding: 0 1rem .5rem 0
  }

  .arcsNav ul{
    display: flex;
    flex-wrap: wrap;
    font-size: 2rem;
    justify-content: space-around;
  }


  
  .archsNav  ul .link, .arcsNav  ul .link {
    color:aliceblue;
    
  }
  
  .archsNav ul .link.active, .arcsNav ul .link.active {
    text-decoration: underline;
  }
  
  .archsNav ul .link:hover, .arcsNav ul .link:hover {
    cursor:pointer;
    color: gray
  }
  
  
  
  .navBoxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 5rem;
  }
  .navBoxes2 {
    display: flex;
    flex-wrap: wrap;
    justify-content:space-around;
    margin-top: 0rem;
  }
  

  
  .boxLink {
    text-decoration: none;
    color:white;
    width: 14rem;
    height: 14rem;
    background-color:rgb(18, 18, 18);;
    border: 5px inset rgba(225, 226, 236, 0.9);
    font-size: 2.3rem;
    font-weight: bold;
    margin: 1rem;
    border-radius: 1rem;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .boxLink:hover {
    border: 5px inset rgba(11, 48, 212, 0.9);
    color: rgba(11, 48, 212, 0.9);
  }
  

  
  
  #arcNav .link{
    font-size: 2rem;
    margin-right: 4rem;
  }
  
  
  
  #archP {
    margin-left: 10%;
    margin-right: 10%;
    font-size: 1.9rem;
  }
  
  .genres h3, .personalities h3, .character h3{
    color:rgb(18, 18, 18);;
  }

#litDevices, #narStyles {
  list-style: none;
}

#img16 {
  border-radius: 2rem;
}

#img12 {
  border-radius: 2rem;
  height: 40rem;
}

#themeList li {
  font-weight: bold;
  list-style: none;
  text-align: center;
}


.genre {
  display: flex;
  flex-wrap: wrap;
  
}
.genre img {
  align-self: flex-end;
}



@media (max-width: 700px) {

  #img16 {
    width: 30rem;
  }
  
  #img12 {
    
    height: 30rem;
  }

  #kurtV {
    width: 75%;
    
  }

  .boxLink {
    font-size: 1.8rem;
    width: 11rem;
    height: 11rem;
    margin: .3rem;
  }

  .boxLink:hover {
    border: 5px inset rgba(225, 226, 236, 0.9);
    color: rgb(200, 200, 200);
  }

  .archsNav ul .link:hover, .arcsNav ul .link:hover {
    color: rgb(200, 200, 200);
  }

}


  @media (max-width: 500px) {


  .boxLink {
    font-size: 3rem;
    width: 24rem;
    height: 16rem;
    margin: .5rem;
  }

  .arcsNav ul, .archsNav ul{
    justify-content: flex-start;
  }

 .arcsNav ul .link, .archsNav ul .link{
  padding: 10px;
  }
  
}