/* Original styles */
#blogHome {
    display: flex;
    flex-direction:column-reverse; /* most recent first */
    gap: 30px;
    padding: 40px;
    border-radius: 10px;
}

.blog-home-top {
  border-radius: 10px;
}

#blogHome .blogTitle {
    font-size: 2rem;
    font-weight: bold;
    
}

.blog-head {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
}

.blog-head:hover {
    transform: scale(1.05);
}

.blogLink {
    text-decoration: none;
    color: inherit;
}


.blog-head .blogImage {
    width:50rem;
    height: 20rem;
    border-radius: 10px;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
    border: 5px inset rgb(28,28,28);
}



.blogTop1:hover .blogImage,
.blogTop2:hover .blogImage,
.blogTop3:hover .blogImage {
    transform: scale(1.1);
}

.blogHeader {
    display: none; /* Hide the blogHeader by default */
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;
    background: rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 8px;
    color: rgb(200, 200, 200);
}

/* Show the blogHeader only when its parent is hovered */
.blog-home-top:hover .blogHeader {
    display: block; /* Reveal the blogHeader on hover */
}

.blogTitle {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.blogHook {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 10px;
}

.blogDate {
    font-size: 14px;
    opacity: 0.8;
    text-align: right;
    margin-top: 10px;
}

/* Responsive styles for smaller screens */

/* For screens smaller than 1000px */
@media (max-width: 1000px) {
    #blogHome {
        gap: 20px; /* Reduce gap between blog items */
        padding: 20px; /* Reduce padding */
    }

    .blogTitle {
        font-size: 20px; /* Slightly smaller title */
    }

    .blogHook {
        font-size: 14px; /* Adjust hook text size */
    }
}

/* For screens smaller than 500px */
@media (max-width: 500px) {
    #blogHome {
        gap: 0px; /* Tighten spacing further */
        padding: 0px; /* Minimal padding for smallest devices */
        overflow: hidden;
        background-color: rgba(11, 13, 17, 0.5);

    }

    #blogHome .blogHeader {
        background-color:rgba(25,28,36,.9);
        color: rgb(200, 200, 200);
        position:relative;
        top: -5rem;
        width: auto;
        left: 5px;
        text-align: left;
        border-radius: .5rem;
        padding: 10px; /* Tighten padding */
        font-size: 14px; /* Adjust font size */

    }


    .blog-home-top {
        width: 100%;
    }

    .blogTitle {
        font-size: 16px; /* Make title fit better */
    }

    .blogHook {
        font-size: 12px; /* Smaller hook text */
    }

    .blogDate {
        font-size: 10px; /* Compact date text */
        margin-right: 4rem;
    }
}
