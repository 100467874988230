.confirm-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.confirm-modal-content {
    background-color: rgb(200, 200, 200);
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    color:black;
}

.modal-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
}

.confirm-button, .cancel-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.confirm-button {
    background-color: red;
    color: rgb(200, 200, 200);
}

.cancel-button {
    background-color: grey;
    color: rgb(200, 200, 200);
}




.toggle-comments {
    background-color: #444;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    width: fit-content;
}

.toggle-comments.active {
    background-color: #222; /* Darker background when replies are visible */
}

.thought-like-button {
    background-color: #444;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    margin-right: 10px;
    width: fit-content;
}

.thought-like-button:hover, .toggle-comments:hover{
    background-color: #555;
}

.thought-buttons {
    display: flex;
    justify-content: space-between;
}

.thought-delete-button {
    background-color: #ff0000;
    color: rgb(200, 200, 200);
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    width: 4rem;
}

.thought-delete-button:hover {
    background-color: #b80404;
}