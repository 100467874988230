.genre-selector-container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  justify-content: center;
}

.scroll-wrapper {
  overflow: hidden;
  width: 570px; /* Shows only 6 icons */
}

.scroll-container {
  display: flex;
  overflow-x: auto; /* Enables horizontal scrolling */
  scroll-behavior: smooth;
  white-space: nowrap;
  scrollbar-width: none; /* Hides scrollbar for Firefox */
}

.scroll-container::-webkit-scrollbar {
  display: none; /* Hides scrollbar for Chrome/Safari */
}

.genre-icon-container {
  flex: 0 0 85px; /* Each icon takes up 85px */
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s;
  margin: 0 5px 0 5px;
}

.genre-icon-container img {
  width: 80px;
}




@media(max-width:1100px){

  .genre-selector-container {
    width: 100%;
  }

  .scroll-wrapper {
    width: 444px; /* Shows only 6 icons */
  }

  .genre-icon-container {
    flex: 0 0 70px; /* Each icon takes up 85px */
    margin: 0 2px 0 2px;
  }

  .genre-icon-container img {
    width: 60px;
  }

  .carousel-btn {
    font-size: 1.5rem;
    font-weight: 800;
    padding: 10px;
    width: 25px;
  }

}

@media(max-width:500px){

  .genre-selector-container {
    width: 360px;
  }

  .scroll-wrapper {
    width: 405px; /* Shows only 6 icons */
  }

  .genre-icon-container {
    flex: 0 0 46px; 
    margin: 0 2px 0 2px;
  }

  .genre-icon-container img {
    width: 46px;
  }

  .carousel-btn {
    font-size: 1rem;
    font-weight: 800;
    padding: 5px;
  }

}

