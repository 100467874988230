
#get-img {
  width: 30rem;
  border-radius: 2rem;
}
  
  .step h3 {
    color: #007bff;
  }
  
 
  
  #stepNav a {
    margin: .3rem 0px .3rem;
  }
  
  
  .step main h3{
    padding: 1rem;
  }
  
  .step {
    border-left: 1px solid white;
    font-size: 1.3rem;
  }
  
  .step-p {
    text-align: left;
    margin: 0px 8rem 0px 8rem;
    font-size: 1.5rem;
    padding: 1rem;
    line-height: 115%;
  }
   .step h4 {
    margin: 0px 8rem 0px 8rem;
    padding: 1rem;
    text-align: left;
  
   }
  
   .step h3 {
    border-top: 1px solid white;;
    border-bottom: 1px solid white;
    padding: 1rem;
    margin-top: 0px;
   }
  
  
  .stepList, .themeList,#supportingCastList {
    display: flex;
    justify-content: center;
    text-align: left;
    font-size: 1.4rem;
  }
  
  .stepList ol, .themeList ol, #supportingCastList{
    display: flexbox;
    justify-content: center;
  }
  
  
  
  .stepList li {
    cursor: pointer;
  
  }
  
  .stepList li span {
    display: none;
  }
  
  #example {
    cursor: pointer;
    font-size: medium;
  }
  #example span {
    display: none;
    
  }
  
  #example:hover span{
    cursor: pointer;
    display: block;
    bottom: 0px;
    margin: 0px;
    padding: .5rem;
    color:  rgb(18, 18, 18);;
    font-weight: normal;
    background: #e5e5e5;
    text-align: left;
    border: 3px solid #666;
    font-size: 1.4rem;
    border-radius: 1rem;
    position: fixed;
    z-index: 100;
    
  }
  
  #example:hover {
    color: gray
  }
  
  .stepList ul li:hover {
    color: gray;
  }
  
  .stepList li:hover span{
    cursor: pointer;
    display: block;
    position: fixed;
    bottom: 0px;
    width: 60%;
    left: 20%;
    color:  rgb(18, 18, 18);;
    background: #e5e5e5;
    text-align: left;
    border: 3px solid #666;
    font-size: 1.1rem;
    border-radius: 1rem;
    z-index: 100;
    padding: .5rem;

  }

  .stepList li:hover span p{
    font-size: 1.2rem;
    
  }

 
  
  .stepList li:hover span h3{
    text-align: center;
  }
  
  .stepList h3 {
    color:rgb(18, 18, 18);;
  }

  .stepList span p {
   padding: 5px;
   
  }
  
  
  #step-five .stepList ol li:hover {
    color: rgb(200, 200, 200);
    cursor:auto;
  }
  
  #step-five li {
    max-width: 40rem;
  }
  
  #step-five ol{
    width: 40rem;
  }
  
  
  
  #settingList {
    display: flex;
    flex-wrap: wrap;
  }
  
  #settingList ul li:hover, #settingList li:hover {
    color: rgb(200, 200, 200);
    cursor: default;
  }
  
  #physical, #cultural {
    display: flex;
    margin-left: 10rem;
  }
  
  #physical li, #cultural li {
    padding: .5rem;
  }
  
  #cultural {
    margin-top: 1.4rem;
  }
  
  .savedInput {
    margin: 2rem;
    text-align: left;
   
  }

  h6 {
    text-align: left;
  }

  .stepArrows #button2{
    margin: .2rem;
  }


  .input {
    display: flex;
    justify-content: center;
  }


.input label{
  margin-right: 5px;
}

.input textarea{
  margin-right: 10px;
  border-radius: 5px;
}


@media (max-width:700px) {

  #threeList {
    display: flex;
    flex-wrap: wrap;
  }

  .step-p {
    text-align: left;
    margin: 0px 2rem 0px 2rem;
    font-size: 1.5rem;
    padding: 1rem;
    line-height: 115%;
  }
  
  .themeList {
    display: flex;
    flex-wrap: wrap;
  }

  #physical, #cultural {
    display: flex;
    flex-wrap: wrap;
    margin-left: 0rem;
  }
  #get-img {
    width: 60%;
  }


}

@media(max-width: 500px){
    #get-idea .sideNav {
      position: relative;
      top:0px;
      left:35%;
    }
}