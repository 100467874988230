.saved-prompts {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Add spacing between prompts */
    justify-content: center;
}
.saved-prompt {
    background-color: rgb(200, 200, 200);
    padding: 2rem;
    color: rgba(25,28,36,.95);
    font-size:  2rem;
    border-radius: 1em;
    max-width: fit-content; /* Ensure prompt does not exceed container width */
    box-shadow: 0 2px 4px rgba(234, 228, 228, 0.3); /* Subtle shadow for better separation */
    
}

.prompt-box {
    word-wrap: break-word; /* Allow text to wrap and break within container */
    max-width: 100%; /* Ensure prompt does not exceed container width */
}

