.genre-selector {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .genre-icon-container {
    position: relative;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  
  .genre-icon-container.selected {
    border: 3px solid #007bff; /* Highlight selected icon */
    border-radius: 50%;
  }
  
  .genre-selector-icon {
    
    border-radius: 50%;

  }
  .genre-selector-icon img {
    width: 10px;
    height: 10px;
  }
  
  .dot-selector {
    display: none;
  }
  