

#write-img {
  height: 25rem;
  width: 40rem;
  border-radius: 2rem;

}

.arcs1 {
    margin: 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  
  }
  
  .arc1 {
    font-size: 1rem;
    width: 14rem;
    justify-content: space-between;
    justify-content: center;
    border: 1px solid white;
    background-color: #15181e;
    text-align: left;
    padding: .5rem;
  }
  
  
  .actList li {
    margin: 1rem;
    width: 90%;
  }
  
  #arcsList {
    list-style: none;
    display: flex;
    justify-content: space-around;
    font-size: 2rem;
  }

  #arcsList .link {
    color:aliceblue;
  }
  
  #arcsList .link.active {
    text-decoration: underline;
  }
  
  #arcsList .link:hover {
    cursor:pointer;
    color: gray
  }

  
  .arcList {
    margin-left: 7rem;
    font-size: larger;
  }

  .stepArrows {
    margin-top: 2rem;
  }

  .plotList {
    margin-left: 15%;
  }

  .step h4 li {
    padding: .4rem;
  }

  .step-p {
    letter-spacing: .3px;
    line-height: 110%;
  }

  @media (max-width: 700px) {
    .plotList {
      margin-left: 0%;
    }
    
    h4 {
      text-align: left;
    }

    .arcList {
      margin-left: 1rem;
    }

    #arcsList {
      font-size: 1.7rem;
        display: flex;
        flex-wrap: wrap;
    }
    #arcsList .link {
      margin: .5rem;
    }

    .arc1 {
      width: 80%;
      margin: 1rem;
      font-size: 2rem;
    }

    #write-img {
      width: 60%;
      height: 80%;
    }
    .sideNav {
      margin-top: 2rem;
    }

  }

  @media(max-width: 900px){
    #write-idea .sideNav {
      position: relative;
      top:0px;
      left:40%;
    }
}