.contests {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.contest {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: rgb(8,8,8);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, box-shadow 0.3s;
}

.contest:hover {
    background-color: rgba(28, 28, 28);
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.contest h3 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 5px;
}

.contest p {
    font-size: 1rem;
    color: #444;
    margin: 0;
}

.contest h4 {
    font-size: 1.2rem;
    color: #666;
    margin-top: 10px;
}

.contest img {
    max-width: 100%;
    border-radius: 8px;
}

.contest .contest-details {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.contest .contest-details span {
    font-size: 0.9rem;
    color: #555;
}

.contest .contest-details .date-range {
    font-style: italic;
}

.contest .contest-details .prize {
    font-weight: bold;
    color: #333;
}


#contests-header:hover {
    color: gray;
    cursor: pointer;
}

.contest-prompt {
    color: rgb(200, 200, 200);
    font-size: 2rem;
    padding: 2rem;
    background-color: rgba(28,28,28);
    font-size:  2rem;
    border-radius: 1em;
}

.contest #prize-img {
    margin-left: 40%;
}

