.user-stats {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.user-stats .story-interaction {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 1.3rem;
    color: rgb(200, 200, 200);
}

.user-stats .story-interaction span {
    display: flex;
    align-items: center;
    gap: 5px;
}

#star {
    color: #ffcc00;

}

#book, #pencil {
    color: rgb(200, 200, 200);
}

#heart {
    color: red;
}

#comment {
    color: lightblue;
}

#follower {
    color: lightgreen;
}

#forum {
    color: purple;
}

@media (max-width: 900px){
    .user-stats {
        justify-content: center;
    }
    .user-stats .story-interaction {
        padding: .5rem;
    }
}