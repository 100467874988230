/* Basic Styling */
.blog-builder h1 {
  text-align: center;
}

/* Control Panel */
.blog-builder-control-panel {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.blog-builder-select,
.blog-builder-input,
.blog-builder-button {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.blog-builder-select {
  width: 150px;
}

.blog-builder-input {
  flex: 1;
}

.blog-builder-button {
  background-color: #007bff;
  color: rgb(200, 200, 200);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.blog-builder-button:hover {
  background-color: #0056b3;
}

/* Blog Preview */
.blog-builder-preview {
  margin: 1rem 0 1rem 0;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  color: #333;
}

.blog-builder-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin-top: 10px;
}

/* Header Styling */
.blog-builder h1,
.blog-builder h2,
.blog-builder h3,
.blog-builder h4,
.blog-builder h5,
.blog-builder h6 {
  font-weight: bold;
  color: #333;
}

.image-container {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Default to left-aligned */
}

.blog-builder-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.image-caption {
  font-style: italic;
  text-align: center;
  color: #555;
  margin-top: 5px;
}

.error-message {
  color: red;
  font-size: 0.9rem;
  margin-top: 5px;
}

/* Flex Container for Images */
.image-flex-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Adjust gap between images */
}

/* Image Sizes */
.image-small {
  width: 150px;
}

.image-medium {
  width: 300px;
}

.image-large {
  width: 500px;
}

/* Handling Alignment of Images */
.element-container {
  position: relative;
  margin-bottom: 20px; /* Adjust spacing */
  text-align: left; /* Default text alignment */
  display: block;
}

/* Handle Header and Paragraph alignment */
.element-container p,
.element-container h1,
.element-container h2,
.element-container h3,
.element-container h4,
.element-container h5,
.element-container h6 {
  margin: 0;
  text-align: inherit; /* Inherit alignment from parent container */
}

/* Specific Styles for Image Containers based on Alignment */
.element-container.image-container {
  display: flex;
  justify-content: center; /* Default to center alignment */
}

.element-container.image-container.left {
  justify-content: flex-start; /* Align image to the left */
}

.element-container.image-container.center {
  justify-content: center; /* Center the image */
}

.element-container.image-container.right {
  justify-content: flex-end; /* Align image to the right */
}

/* Delete Button Styling */
.blog-builder-delete-button {
  position: absolute;
  bottom: 10px;
  right: -20px;
  background: gray;
  color: rgb(200, 200, 200);
  border: none;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.delete-button:hover {
  background: darkred;
}
