.merch-carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 19rem;
    background-color: rgb(8, 8, 8);
    border-radius: 1rem;
    padding: 1rem;
}

.merch-carousel-item .merchTop {
    color: rgb(200, 200, 200);
    text-decoration: none;
    background-color: rgb(18, 18, 18);
    border-radius: 2rem;
}

.merchImage {
    border-radius: 20px;
    height: 18.5rem;
    width: auto;
}

.merch-carousel-prev, .merch-carousel-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    color: rgb(18, 18, 18);;
    font-size: 40px;
    height: 40px;
    line-height: 35px;
    text-align: center;
    width: 40px;
    cursor: pointer;
    transition: background 0.3s ease;
    padding: 3px;
}

.merch-carousel-prev {
    left: 10px; /* Adjust for preferred distance from left edge */
}

.merch-carousel-next {
    right: 10px; /* Adjust for preferred distance from right edge */
}

.merch-carousel-next:hover, .merch-carousel-prev:hover {
    background: rgba(0, 0, 0, 0.8);
    color: #aaaaaa;
    cursor: pointer;
}

