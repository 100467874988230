
.leader-card {
    display: flex;
    flex-wrap: wrap;
    border-radius: 8px;
    padding: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: rgb(18, 18, 18);;
    margin-bottom: 4px;
    align-items: center;
    justify-content: space-between;
    font-size: .9rem;
}

.leaderboard-info {
    display: flex;
    flex-wrap: nowrap;
}

.leaderboard-sub-info {
    text-align: left;
    margin-left:5px;
    line-height: 1.3rem;
}
.leaderboard-list li::marker {
    font-size: .7rem;
    color: white;
}


.leader-names {
    font-size: .8rem;
}
.leader-link {
    text-decoration: none;
    color: #007bff;
}
.leader-link:hover {
    cursor: pointer;
    color: rgb(200, 200, 200);
}

#leader-follow {
    padding: 3px;
}
#leader-follow:hover{
    cursor: pointer;
    color: rgba(19, 53, 205);
}

@media(max-width: 700px){
    .leader-card{
        background-color:rgb(18, 18, 18);;
       
    }
}
@media (max-width: 500px){
    .leader-card {
        font-size: 1.2rem;
    }

}

