.selector {
    padding: 1.5rem;
    border: 2px solid #4a90e2; /* Border color */
    border-radius: 8px; /* Rounded corners */
    max-width: 600px; /* Maximum width */
    margin: 2rem;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Shadow for depth */
    display: flex;
}

.selector legend {
    font-size: 1.5rem; /* Legend font size */
    font-weight: bold; /* Bold text */
    color: rgb(200, 200, 200); /* Text color */
    margin-bottom: 1rem; /* Space below legend */
    text-align: center; /* Center text */
}


.check {
    width: 48%; /* Set width for each checkbox group */
    display: flex; /* Keep checkboxes in a vertical stack */
    flex-direction: column; /* Stack items vertically */
    margin-bottom: 1rem; /* Space below checkbox section */
}

.check div {
    display: flex; /* Flex layout for individual checkbox items */
    align-items: center; /* Center items vertically */
}

.check input[type="checkbox"] {
    width: 1rem; /* Checkbox width */
    height: 1rem; /* Checkbox height */
    margin-right: 0.5rem; /* Space between checkbox and label */
    accent-color: #4a90e2; /* Custom checkbox color */
    cursor: pointer; /* Pointer cursor */
}

.check label {
    font-size: 1rem; /* Label font size */
    color: #ffffff; /* Label color */
    cursor: pointer; /* Pointer cursor */
    transition: color 0.2s ease; /* Transition effect for hover */
}

.check label:hover {
    color: #cccccc; /* Change color on hover */
}

/* Responsive styles */
@media (max-width: 768px) {
    .check-container {
        flex-direction: column; /* Stack the check containers on smaller screens */
    }
    
    .check {
        width: 100%; /* Full width for each checkbox group */
    }
}
