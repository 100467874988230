
  

  
  .login-container {
    width: 100%;
    color: #333;
  }
  
  .login-form-container {
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .login-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .login-field label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .login-field input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  #login-button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #4f46e5;
    color: rgb(200, 200, 200);
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
  }
  
  #login-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  #login-button:hover:not(:disabled) {
    background-color: #4338ca;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  
  .error-message {
    color: red;
    font-weight: bold;
  }
  
  .register-link {
    text-align: center;
    margin-top: 10px;
  }
  
  .register-link a {
    text-decoration: underline;
    font-weight: bold;
  }
  
  .divider {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
  
  .divider div {
    flex-grow: 1;
    border-bottom: 2px solid #ccc;
  }
  
  .divider span {
    margin: 0 10px;
    font-weight: bold;
  }
  
  .google-signin {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: rgb(200, 200, 200);
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .google-signin:hover:not(:disabled) {
    background-color: #f0f0f0;
  }
  
  .google-signin svg {
    width: 20px;
    height: 20px;
  }
  
  .google-signin:disabled {
    cursor: not-allowed;
  }

  .error-message {
    color: red;
    margin-top: 10px;
    display: block;
  }
  
  .success-message {
    color: green;
    margin-top: 10px;
    display: block;
  }
  
  /* Style for the reset password button */
  .reset-password-button {
    background: none;
    border: none;
    color: #007BFF;
    cursor: pointer;
    text-decoration: underline;
    padding: 0;
  }
  
  .reset-password-button:disabled {
    color: gray;
    cursor: not-allowed;
  }
  
  /* Optional: Style to align the "Forgot Password" link */
  .forgot-password-link {
    text-align: left;
    font-size: .8rem;
    margin-right: 2rem;
  }
  

  @media (max-width: 500px){
    .login-form-container {
      width: 75%;
    }
    .register-link {
      font-size: 1.4rem;
    }
    .forgot-password-link {
   
      font-size: 1.1rem;
    }
  }


