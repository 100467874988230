/* Base styles for larger screens */
.app {
  display: flex;
  background-image: url(/public/images/backgrounds/beautiful-mountains-landscape.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100vh; /* Full viewport height */
  overflow: hidden; /* Prevent scrolling on large screens */
  justify-content: center;


}

/* Navigation styles */
.Nav {
  flex: 0 0 305px; /* Fixed width for larger screens */
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  background-color: rgba(8, 8, 8, 0.9);
}

/* Main content styles */
.mainOutlet {
  flex: 0 0 975px; /* Fixed width */
  overflow-y: auto; /* Enable scrolling */
  border: 1px solid rgb(50, 50, 50);
}

/* Motto container styles */
.motto-container {
  width: auto;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(8, 8, 8, 0.9);
}

#motto {
  border-radius: 1rem;
  font-size: 5rem;
  padding: 5rem;
  margin: 2rem;
  font-weight: bold;
  background-image: url(/public/images/backgrounds/beautiful-mountains-landscape.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

/* Responsive styling for screens between 1280px and 1100px */
@media (max-width: 1280px) {
  .Nav {
    flex: 1 1 305px; /* Allow shrinking */
    height: auto; /* Allow content to dictate height */
    overflow-y: auto; /* Enable scrolling */
  }

  .mainOutlet {
    border: none; /* Remove unnecessary border */
  }
}

@media (max-width: 1100px) {
  .Nav {
    flex: 0 0 100px; /* Shrink Nav */
  }

  .mainOutlet {
    flex: 0 0 800px; /* Adjust main content width */
  }
}

/* Stacking layout below 900px */
@media (max-width: 900px) {
  .app {
    display: flex;
    flex-direction: column; /* Stack Nav and mainOutlet vertically */
    align-items: center;
    justify-content: flex-start; /* Align content to the top */
    height: auto; /* Allow normal scrolling */
    overflow: auto; /* Ensure scrollability */
    background-image:none;

  }

  .Nav {
    max-width: 700px;
    width: 100%;
    height: auto; /* Allow height to grow with content */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: visible; /* Ensure content is visible */
  }

  .mainOutlet {
    max-width: 700px;
    width: 100%;
    overflow-x: hidden
  }
}
