.user-prompts-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem; /* Spacing between elements */
    padding: 1rem;
}

.user-prompts-page h4 {
    font-weight: bold;
    font-size: 1.5rem;
    margin-bottom: 1rem;
}


.user-prompts-page .warning {
    color: #ff4d4d;
    font-size: 0.9rem;
    margin-top: -0.5rem;
}



.user-prompts-page button:hover {
    background-color: #0056b3;
}

.user-prompts {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Add spacing between prompts */
    justify-content: center;
}

.user-prompt {
    background-color: rgb(28, 28, 28);
    padding: .5rem;
    color: white;
    font-size: 1.1rem;
    border-radius: 1em;
    box-shadow: 0 2px 4px rgba(234, 228, 228, 0.3); /* Subtle shadow for better separation */
    width: 16rem;
    height: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.userPrompt-delete-button {
    background-color: #ff4d4d;
    color: rgb(200, 200, 200);
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    margin: 0.5rem 0;
  }
  
.userPrompt-delete-button:hover {
    background-color: #ff1a1a;
  }

.user-prompt h5 {
    font-weight: 600;
}

.user-prompt-text:hover {
    background-color: #007bff;
    color: rgb(200, 200, 200);
    cursor: pointer;
}

.user-prompt-text {
    padding: 1rem;
    background-color: rgb(8,8,8);
    border-radius: 10px;
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* Limit to 4 lines */
    -webkit-box-orient: vertical;
    display: flex;
    align-items: center;
    justify-content: center;
}

.user-prompt-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.9rem;
}

.user-prompt-bottom button {
    background-color: #007bff;
    color: rgb(200, 200, 200);
    font-size: 0.9rem;
    padding: 0.4rem 0.8rem;
    border: none;
    border-radius: 6px;
    cursor: pointer;
}

.user-prompt-bottom button:hover {
    background-color: #0056b3;
}

@media (max-width: 700px) {
    .user-prompt {
        width: 22rem;
        height: 22rem;
        font-size: 1.2rem;
    }

    .user-prompts-page textarea {
        height: 4rem;
    }
}



.confirm-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .confirm-modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
  
  .confirm-yes {
    background-color: #ff4d4d;
    color: rgb(200, 200, 200);
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .confirm-yes:hover {
    background-color: #ff1a1a;
  }
  
  .confirm-no {
    background-color: #ccc;
    color: black;
    padding: 10px 20px;
    border: none;
   
  }  



  .set-featured-button {
    background-color: #0056b3;
    color: rgb(200, 200, 200);
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .set-featured-button:hover {
    background-color: #004494;
  }


  
  

.prompt-input {
    width: 200px; /* Initial width */
    height: 30px; /* Initial height, looks like an input box */
    padding: 10px;
    font-size: 1rem;
    transition: all 0.3s ease; /* Smooth transition effect */
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: none; /* Disable manual resizing */
    box-shadow: none;
    outline: none;
}

/* Expanded state when clicked (focused) */
.prompt-input.expanded {
    width: 400px; /* Expands to full width */
    height: 150px; /* Expands to a paragraph-size height */
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* A slight shadow effect when focused */
}

.prompt-user-link {
    font-weight: 800;
    color:#007bff;
    text-decoration: none;
}
.prompt-user-link:hover {
    color: gray;
}

@media(max-width: 500px){
    .prompt-input {
        width: 200px;
    }

    .prompt-input.expanded {
        width: 90%; /* Expands to full width */
        height: 100px; /* Expands to a paragraph-size height */
        border-color: #007bff;
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* A slight shadow effect when focused */
    }
   
}