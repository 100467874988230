.featured-story {
    background-color: rgb(28, 28, 28);
    color: rgb(200, 200, 200);
    border-radius: 10px;
    padding: 1rem;
    margin: .5rem;

}

.featured-story:hover {
    cursor: pointer;
    background-color: rgba(28, 28, 28);

}


.featured-story .story-interaction span {
    padding-right: 7px;
}

.potd-prompt-home {
    color: rgb(200, 200, 200);
    background-color: rgb(8, 8, 8);
    padding: 12px;
    border-radius: 10px;
    font-weight: 600;
  }