/* users.css */

.users-container header {
    text-align: center;
    margin-bottom: 20px;
}

.users-list {
    display: flex;
    flex-wrap: wrap;
}

.user-card {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: rgb(8,8,8);
    color: rgb(200,200,200);
}

.user-card p {
    margin: 10px 0;
}

.user-card p strong {
    display: inline-block;
    width: 120px;
}

.users-list .username {
color: black;
text-decoration: none;
width: 100%;
}

.user-card:hover {
    cursor: pointer;
    background-color: rgb(18,18,18);
    border: 2px solid rgba(11, 48, 212, 0.9);

}

.search-bar {
    border-radius: 8px;
    padding: 5px;
    margin: 1rem;
}

.penName {
    margin-right: 5px;
    margin-left: 5px;
    font-weight:800;
}

.aka {
    margin-right: 5px;
    margin-left: 5px;

}

#users-img {
    width: 50px;
}
@media(max-width:500px){
    .user-card p {
        padding-right: 10px;
    }
    #users-img {
        width: 40px;
    }
}