.admin-page {
    background-color:rgba(25,28,36,.90);
    padding: 2rem;
}

.admin-page h1 {
    margin: 2rem;
    color: rgb(200, 200, 200); /* Ensures the text is visible against the black background */
}

.admin-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 300px); /* Fixed height for each row */
    gap: 10px; /* Space between grid items */
    max-width: 100%;
    margin: 0 auto;
}

.admin-content > div {
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 15px;
    overflow-y: auto; /* Enables scroll if content exceeds height */
    background-color:rgba(2, 11, 29, 0.80);
    color: rgb(200, 200, 200); /* Ensures text inside components is visible */
}

.admin-content > div::-webkit-scrollbar {
    width: 8px;
}

.admin-content > div::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 8px;
}

.admin-content > div::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}


/* Activity line styling */
.admin-activity-line {
    padding-bottom: 0.4rem;
    color: rgb(200, 200, 200);
    margin: 1rem;
    border-radius: 0.5rem;
}

/* User management section */
.user-management {
    padding: 20px;
}

.user-management h1 {
    text-align: center;
    color: rgb(200, 200, 200);
}

.user-management input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.user-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.user-list div {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.user-list div:hover {
    background-color: #f0f0f0;
}

.user-edit {
    margin-top: 20px;
}

.user-edit h2 {
    text-align: center;
    color: rgb(200, 200, 200);
}

.user-edit form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.user-edit label {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    color: rgb(200, 200, 200);
}

.user-edit input {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.user-edit button {
    padding: 10px;
    background-color: #007bff;
    color: rgb(200, 200, 200);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.user-edit button:hover {
    background-color: #0056b3;
}

.total-stats {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.plot-type-stats {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.stats-item {
    padding: 1rem;
}

.admin-main {
    display: flex;
}


.message-list {
    margin-top: 20px;
}

.message-item {
    border: 1px solid #ddd;
    padding: 10px;
    margin: 5px 0;
    border-radius: 5px;
}

.message-dropdown {
    padding-left: 15px; /* Indent for visual separation */
}

h4 {
    margin: 10px 0;
    cursor: pointer;
}


