.create-contest {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
}

.create-contest h1 {
    text-align: center;
    margin-bottom: 20px;
}

.create-contest .form-group {
    margin-bottom: 15px;
}

.create-contest .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.create-contest .form-group input,
.create-contest .form-group textarea,
.create-contest .form-group select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.create-contest .form-group textarea {
    height: 100px;
    resize: vertical;
}

.create-contest .form-group input[type="number"] {
    -moz-appearance: textfield; /* Hide the spinner on Firefox */
}

.create-contest .form-group input[type="file"] {
    padding: 0;
}

.create-contest button {
    display: block;
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #28a745;
    color: rgb(200, 200, 200);
    font-size: 16px;
    cursor: pointer;
}

.create-contest button:hover {
    background-color: #218838;
}
