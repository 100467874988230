/* Ensure this stylesheet is imported in your Contest component */
.contest-header {
    background-color: rgb(8,8,8); /* Light background color for contrast */
    border: 1px solid #ddd;    /* Subtle border */
    border-radius: 8px;        /* Rounded corners */
    padding: 20px;             /* Padding for spacing */
    margin: 20px 0;            /* Margin to separate from other content */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
}

.contest-header h2 {
    font-size: 2rem;           /* Larger font size for the title */
    margin-bottom: 10px;       /* Space below the title */
}

.contest-header p {
    font-size: 1rem;           /* Standard font size for paragraphs */
    color: #555;               /* Slightly lighter color for paragraphs */
    margin: 1rem;
    
}

.contest-description {
    text-align: center;
}

.contest-header .date-range {
    font-style: italic;        /* Italicize date range for distinction */
}

.contest-header .prize {
    font-weight: bold;         /* Bold prize text to highlight it */
    color: #e67e22;            /* Distinct color for prize */
}
