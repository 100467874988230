

.profHome {
  padding: 1rem;
  background-color: rgb(18, 18, 18);
  border-radius: 1rem;

}

.prof-boxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

}
.infoBox {
    border-radius: 1rem;
    text-align: left;
    background-color: rgb(8, 8, 8);
    color: rgba(200,200,200);
    padding: .8rem;
    height: fit-content;
    width: 100%;
}

.infoBox h5 {
    text-decoration: underline;
}
.infoBox p {
    color: gray;
}
#page-box {
    border-radius: 1rem;
    width: 100%;

}
.bookmark {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color:  rgb(8, 8, 8);
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 5px;
  width: 80%;
}
.bm-link {
  color: gray;
  text-decoration: none;
  
}
.bm-link:hover {
  cursor: pointer;
  color: darkgray;
}

.bm-delete {
  margin-left: 20px;
  padding: 1px;
  color: rgb(200, 200, 200);

}

.bm-delete:hover{
  cursor: pointer;
  color: red;
  border-radius: 50%;
}

.favorites-page {
color: rgb(18, 18, 18);}

#prof-header {
    padding: 5rem;
}

.prof-nav {
    display: flex;
    justify-content: center;
}

.prof-nav h3 {
    padding: 10px;
    margin-right: 1rem;
    cursor: pointer;
    border-radius: .5rem;
}

  
  .prof-nav .active {
    color:rgba(25,28,36,.95);
    background-color: rgb(200, 200, 200);
  }
  
  /* Optional: Additional styles for hover effect */
  .prof-nav h3:hover {
    background-color: #444;
  }


  .social-media-icons {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }
  
  .social-media-icons a {
    color: #333;
    text-decoration: none;
  }
  
  .social-media-icons a:hover {
    color: #0077b5; /* Example color on hover */
  }

  @media (max-width: 1000px){
    .prof-boxes {
      flex-wrap: wrap;
    }
    .infoBox {
      margin: 2rem;
      width:100%;
    }
  
  }

  @media(max-width: 770px){
    .prof-nav h3{
      padding: 5px;
      margin-right: .5rem;
    }
  }

  @media(max-width: 570px){
    .prof-nav{
      flex-wrap: wrap;
    }
  }