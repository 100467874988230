/* Root container for the rolodex */
.rolodex {
    overflow: hidden;
    position: relative;
    background-color: rgb(8, 8, 8);
    border-radius: 10px;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(40, 44, 52);
}

/* Scrollable container for the news items */
.news-items-container {
    width: 100%;
    max-height: 100%;
    overflow-y: auto; /* Makes the items scrollable */
    scrollbar-width: thin; /* For Firefox */
    scroll-behavior: smooth; /* Smooth scrolling effect */
}

.news-items-container::-webkit-scrollbar {
    width: 8px; /* Adjust scrollbar width */
}

.news-items-container::-webkit-scrollbar-thumb {
    background-color: rgba(40, 44, 52, 0.5);
    border-radius: 10px;
}

.news-items-container::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

/* Styling each news item */
.news-item {
    width: 100%;
    padding: 15px;
    margin-bottom: 10px;
    background-color: rgb(28, 28, 28);
    border: 1px solid #ccc;
    border-radius: 8px;
    text-align: left;
    box-sizing: border-box;
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-decoration: none;
    color: rgb(200, 200, 200);
}

.news-item h4 {
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    color: #007bff;
}

.news-item p {
    font-size: 1rem;
}

.news-item .news-date {
    font-size: 0.8rem;
    color: #777;
}

/* Optional: add some styling on hover for the news items */
.news-item:hover {
    cursor: pointer;
    background-color: rgb(28, 28, 28);

}


@media (max-width: 700px) {
    .rolodex {
        width: auto;
        height: 24rem;
    };

}