.story-premise textarea {
    width: 40rem;
    height: 4rem;
}

.premise-text {
    background-color: rgb(18, 18, 18);;
    border-radius: 10px;
    padding: 1rem;
    text-align: left;
    width: 75%;
    margin-left: 12.5%;
    margin-bottom: 1rem;
}

.header-with-info {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.info-icon {
    font-size: 10px;
    font-weight: bold;
    color: #555;
    background-color: #e0e0e0;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
}

/* Custom tooltip styling */
.info-icon:hover::after {
    content: attr(data-tooltip);
    position: absolute;
    top: 25px;
    right: 0;
    width: 200px;
    background-color: #f9f9f9;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    font-size: 12px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    white-space: normal;
    z-index: 10;
}


@media(max-width: 700px){
    .story-premise textarea {
        width: 80%;
    }
}