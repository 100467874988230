/* NAV */
.navbar {
  display: none;
}

.nav {
  padding: 1rem;
  font-size: 1.2rem;
  color: rgb(200, 200, 200);
  border-radius: .6rem;
}

#logos {
  display: flex;
  align-items: center;
}

#Wlogo {
  width: 10rem;
  padding: 10px;
  margin-left:50%;
}

#favLogo {
  width: 4rem;
  height: 4rem;
  display: none;
}

.nav-list {
  list-style: none;
  margin: 0;

}

.navLink {
  display: block; 
  padding: 1rem 6px 6px 6px;
  text-decoration: none; 
  color: rgb(200, 200, 200); 
  cursor: pointer;

}

.navLink:hover {
  color: white;
  background-color: rgb(18, 18, 18);
}

.nav-pair {
  display: flex;

}

.nav-pair > div {
  padding-right: 1rem;
}

.nav-icon {
  color: rgb(250, 250, 250);
}

#nav-icon-q{
  padding-right: 1.5rem;

}

.nav-group {
  display: flex;
  justify-content: space-between;
}

.nav-chevron {
  font-size: .7rem;
}

/* Dropdown content styling */
.dropdown-content {
  display: none; /* Initially hide dropdown content */
  position: absolute; /* Position relative to the dropdown button */
  background-color: rgb(8, 8, 8);
  border-radius: 1rem;
  width: 100%;
  z-index: 1;
  top: 100%; /* Position directly below the dropdown button */
  left: 0;
}

.dropdown-content.navLink {
  display: block; /* Make dropdown items block-level */
  padding: 5px 10px; /* Add spacing for dropdown items */
  text-decoration: none; /* Remove underline */
}

.dropdown-content a:hover {
  background-color: #eee; /* Optional hover effect */
}

/* When dropdown is active, show content */
.dropdown-open .dropdown-content {
  display: block; /* Show dropdown content when active */
}

.mobile-search {
  display: none;
}

@media (max-width: 1270px) {
  .nav-list {
    margin: 0;
  }

  #Wlogo {  
    margin-left: 0;
  }

  .nav-pair > div {
    padding-bottom: 5px;
  }

  .nav-pair {
    display: block;
    font-size: 1rem;
    text-align: center;
  }
  .nav-chevron {
    display: none;
  }

  .nav-group {
    justify-content: center;
  }

  #favLogo {
    display: block;
    padding-left: 20px;

    
  }
  #Wlogo {
    display: none;
  }
  #logos {
    justify-content: center;
    margin-bottom: 1rem;

  }
}

@media (max-width: 1160px) {
 .nav-name {
  display: none;
 }
 #favLogo {
  width: 3rem;
  height: 3rem;
 }
}

@media (max-width: 900px) {
  .nav {
    display: flex;
    align-items: center;
    justify-content: left;
    width: 97.5%;
    padding: 2px;
    flex-wrap: nowrap;
    background-color: rgb(18, 18, 18);
    justify-content: space-between;
}

  .nav-list,
  #favLogo,
  #Wlogo,
  .wide-search {
    display: none; /* Hide unnecessary elements */
  }

  .mobile-search {
    display: block; /* Ensure mobile search is visible */
  }

  .navbar {
    display: block;
    overflow: hidden;
    margin-left: 22%;
    font-size: 0.8rem;
  }

  /* Dropdown styles */
  .dropdown {
    position: relative;
    display: inline-block;
    overflow: visible;
  }

  .dropdown .dropbtn {
    border: none;
    border-radius: 1rem;
    background-color: rgb(8, 8, 8);
    margin: 0 25px;
    position: relative;
  }

  .dropbtn img {
    width: 4rem;
    height: 4rem;
  }

  .dropdown:hover .dropbtn {
    border: 2px inset silver;
  }

  .navbar, .dropdown {
    overflow: visible !important;
  }

  /* Dropdown content */
  .dropdown-content {
    position: fixed;
  top: 88px; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  width: 98vw; /* Ensures it doesn't exceed screen width */
  background-color: rgb(8, 8, 8);
  border-radius: 1rem;
  border: 4px inset white;
  z-index: 1000; /* Ensure it’s above other elements */
    
  }

  .dropdown-content .dropLink {
    display: block;
    padding: 0.65rem 0.6rem;
    color: rgb(200, 200, 200);
    text-align: center;
    font-size: 1.1rem;
    text-decoration: none;
    border-bottom: 1px solid white;
  }

  /* Show dropdown content when active */
  .dropdown.active .dropdown-content {
    display: block;
  }
}

@media (max-width: 500px) {
  .dropdown-content .dropLink {
    font-size: 1.6rem;
  }

  .dropdown-content {
    position: fixed;
  top: 80px; /* Adjust as needed */
  }

}

