


#blogHome .blogDate {
    color: lightblue;
    text-align: right;
}

.blogLink {
    text-decoration: none;
}


.blogPost {
    background-color:rgba(8,8,8);
    border-radius: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr 4fr;
    grid-template-rows: 1fr 1fr 4fr ;
    grid-template-areas: 
    'left left  top'
    'left left section'
    'free  free section'
    
    ;

}

.blogLeft {
    grid-area: left;
    margin-top:2rem;
    margin-left:1rem;
    display: flex;
    flex-direction: column;
}
.blogTop {
    grid-area: top;
}


.blogSection {
    font-size: 1.4rem;
    grid-area: section;
    text-align: left;
    margin-left: 2rem;
}


.blogPost .blogImage {
    border-radius: 1rem;
    box-shadow: 5px 3px 3px black;

}


.blogPost .blogTop {
    margin: 3rem 3rem 0 2rem;
    background-color: rgb(255, 255, 255, .75);
  color: rgb(18, 18, 18);
    padding: .5rem;
    border-radius: .5rem;
    height: fit-content;
}


.blogPost .blogTitle {
    font-size: 2.6rem;
    font-weight: bold;
    text-align: left;

}
.blogPost .blogDate {
    color: gray;
    padding-left: 1rem;

}

.blogPost .subtitle {
    font-size: 1.4rem;
   text-align: left;
   margin-top: 1rem;
}


.blogNav {
    text-align: left;
    font-size: 1.2rem;
    background-color: rgba(8, 9, 10, 0.2);
    border-radius: 2rem;
    box-shadow: 5px 1px 1px black;
}

.blogNav h4 {
    margin-left: 2rem;
text-decoration: underline;}

.blogNav ul{
    list-style:circle;
    padding-right: 2rem;
}
.blogNav a {
    color: rgb(200, 200, 200);
    text-decoration: none;
}

.blogNav li:hover{
    color: gray;
    cursor: pointer;
}




.blogPost p {
    text-align: left;
}


@media (max-width: 900px) {


}

@media (max-width: 700px) {
   
     #blogHome .blogImage {
        width: 44rem;
        height: 26rem;
        border-radius: 1rem;
    
    }

   
    .blogPost {
        display: block;
        ;
    }
    

}

@media(max-width:500px){

    #blogHome .blogImage {
        width: 30rem;
        height: 20rem;
        border-radius: 1rem;
    
    }

    #blogHome .blogHeader {
        width: 30rem;
    }

    .blogPost {
        margin-left:0rem;
      
    }

    .blogPost p {
        font-size: 2rem;
    }
    .blogLeft {
        margin-left:0rem;

    }

    
   
}


