.message {
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 1rem;
    background-color: #ffffff;
    color: rgba(40, 44, 52);
}

.message label {
    display: block;
    margin-bottom: 10px;
}

.meddage input, .message select, .message textarea {
    width: 90%;
    padding: 8px;
    margin-top: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.error {
    color: red;
    font-weight: bold;
    margin-top: 10px;
}

.message button {
    margin-top: 15px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.message button:hover {
    background-color: #0056b3;
}
